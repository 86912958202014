import * as React from "react"

// styles
const pageStyles = {
  color: "#232129",
  padding: "60px 96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 34,
  maxWidth: 220,
}
const headingAccentStyles = {
  color: "#663399",
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}
const listStyles = {
  marginBottom: 96,
  paddingLeft: 0,
}
const listItemStyles = {
  fontWeight: 300,
  fontSize: 24,
  maxWidth: 560,
  marginBottom: 30,
}

const linkStyle = {
  color: "#8954A8",
  fontWeight: "bold",
  fontSize: 16,
  verticalAlign: "5%",
}

const docLinkStyle = {
  ...linkStyle,
  listStyleType: "none",
  marginBottom: 24,
}

const descriptionStyle = {
  color: "#232129",
  fontSize: 14,
  marginTop: 10,
  marginBottom: 0,
  lineHeight: 1.25,
}

const docLink = {
  text: "文章列表",
  // url: "https://www.gatsbyjs.com/docs/",
  // color: "#8954A8",
}

const badgeStyle = {
  color: "#fff",
  backgroundColor: "#088413",
  border: "1px solid #088413",
  fontSize: 11,
  fontWeight: "bold",
  letterSpacing: 1,
  borderRadius: 4,
  padding: "4px 6px",
  display: "inline-block",
  position: "relative",
  top: -2,
  marginLeft: 10,
  lineHeight: 1,
  color: '#c0c1c4',
}

// data
const links = [
  {
    text: "在 nodejs 中原生使用 Fetch API",
    url: "https://fusebit.io/blog/node-fetch/",
    description:
      "undici 是一个新的性能更强，延时更小，吞吐更高的 node http client，目前最新版 nodejs (17.5.0) 将 undici 作为它的依赖(可见源码 deps 目录)，并借助于它可在 nodejs 中直接使用 Fetch API",
    color: "#E95800",
  },
  {
    text: "2021 年在 Web 领域有哪些关键进展？",
    url: "https://mp.weixin.qq.com/s/frlfZ9iRNyAMMN_m1rS8Zw",
    description:
      "W3C 领域在 2021 的一些发展列举如下",
    color: "#1099A8",
  },
  {
    text: "2021 CSS 使用趋势",
    url: "https://juejin.cn/post/7039547479997546533",
    description: "",
    color: "#BC027F",
  },
  {
    text: "2021 Javascript 使用趋势",
    url: "https://juejin.cn/post/7045150888171667492",
    description: "",
    color: "#0D96F2",
  },
  {
    text: "2021 CSS 使用状况年度报告",
    url: "https://2021.stateofcss.com/zh-hans/about/",
    description:
      "关于 2021 年的 CSS 使用状况调查报告。",
    color: "#8EB814",
  },
  {
    text: "为什么 Vue 和 vite 源码抛弃了 yarn，迁移 pnpm？",
    url: "https://mp.weixin.qq.com/s/Hzqy-XX51TiFz5vx4ZPR2Q",
    badge: true,
    description:
      "esbuild 在 v0.13 之后使用了 optionalDependencies 来安装某些不同平台的依赖(相关 pr 可以参考: install using “optionalDependencies” ）。",
    color: "#663399",
  },
]

const footerStyle = {
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: '100%',
  height: '60px',
  lineHeight: '60px',
  textAlign: 'center',
  color: '#c0c1c4',
  fontSize: '12px',
  backgroundColor: '#fff',
}
const copyrightStyle = {
  color: '#c0c1c4',
  fontSize: '12px',
  // textDecoration: 'none',

}

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <title>响闻网</title>
      <h1 style={headingStyles}>
        响闻网
        <br />
        <span style={headingAccentStyles}></span>
        <span role="img" aria-label="Party popper emojis">
          🎉🎉🎉
        </span>
      </h1>
      <p>
        <span role="img" aria-label="Sunglasses smiley emoji">
          😎
        </span>
      </p>
      <ul style={listStyles}>
        <li style={docLinkStyle}>
          <a
            style={linkStyle}
            // href={`${docLink.url}?utm_source=starter&utm_medium=start-page&utm_campaign=minimal-starter`}
          >
            {docLink.text}
          </a>
        </li>
        {links.map(link => (
          <li key={link.url} style={{ ...listItemStyles, color: link.color }}>
            <span>
              <a
                style={linkStyle}
                href={`${link.url}?utm_source=starter&utm_medium=start-page&utm_campaign=minimal-starter`}
              >
                {link.text}
              </a>
              {link.badge && (
                <span style={badgeStyle} aria-label="New Badge">
                  NEW!
                </span>
              )}
              <p style={descriptionStyle}>{link.description}</p>
            </span>
          </li>
        ))}
      </ul>
      <footer style={footerStyle}>
        响闻网 © CopyRight 2022 <a target="_blank" style={copyrightStyle} href="https://beian.miit.gov.cn/">浙ICP备20003753号-1</a>
      </footer>
    </main>
  )
}

export default IndexPage
